import { useState, useEffect } from "react";

export const MEDIA_RESOLUTION = Object.freeze({
  isMobile: "(max-width: 600px)",
  isDesktop: "(min-width: 1000px)"
});

export const useViewport = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const mobileMedia = window.matchMedia(MEDIA_RESOLUTION.isMobile);
    const desktopMedia = window.matchMedia(MEDIA_RESOLUTION.isDesktop);
    const listener = () => {
      setIsMobile(mobileMedia.matches);

      setIsDesktop(desktopMedia.matches);
    };

    if (mobileMedia.matches !== isMobile) {
      setIsMobile(mobileMedia.matches);
    }

    if (desktopMedia.matches !== isDesktop) {
      setIsDesktop(desktopMedia.matches);
    }

    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [isMobile, isDesktop]);

  return { isMobile, isDesktop };
};
