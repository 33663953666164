import { useState, useMemo, useEffect } from "react";

type ObserverFunction = (element?: HTMLElement) => void;

interface UseResizeInterface {
  elementSize: { height: number; width: number; top: number };
  observe: ObserverFunction;
  unobserve: ObserverFunction;
}

export const useResize = (element?: HTMLElement): UseResizeInterface => {
  const [elementSize, setElementSize] = useState({
    height: 0,
    width: 0,
    top: 0,
  });
  const [didMount, setDidMount] = useState(false);
  // Gatsby build uses server-side rendering which cannot use window object
  const resizeObserver = useMemo(
    () =>
      typeof ResizeObserver !== "undefined"
        ? new ResizeObserver((entries) => {
            const entry = [...entries].pop();
            const { height, width } = entry.contentRect;
            const { offsetTop } = entry.target;
            setElementSize({ height, width, top: offsetTop });
          })
        : undefined,
    []
  );

  const observe: ObserverFunction = (element) =>
    element && resizeObserver.observe(element);

  const unobserve: ObserverFunction = (element) =>
    element && resizeObserver.unobserve(element);

  useEffect(() => {
    setDidMount(true);
  }, []);

  useEffect(() => {
    didMount && element && resizeObserver.observe(element);
    return () => element && resizeObserver.unobserve(element);
  }, [element, didMount]);

  return { elementSize, observe, unobserve };
};
