import * as React from "react";

export const useScrollPosition = () => {
  const [pageYPosition, setPageYPosition] = React.useState(
    typeof window !== "undefined" ? window.pageYOffset : 0
  );

  React.useEffect(() => {
    const handleScroll = () => {
      setPageYPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return { pageYPosition };
};
