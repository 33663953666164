import * as React from "react";
import styled from "styled-components";
import { Link, navigate } from "gatsby";

import { Align, Title, Text, Button } from "../../UI";

import { defaultTheme } from "../../../styles/theme";
import { respondTo } from "../../../styles/respondTo";
import { useText } from "../../../common/hooks";

const messageSent = require("../../../assets/images/message_sent.svg");
const logoGrey = require("../../../assets/images/logo-grey.svg");
const arrowBack = require("../../../assets/images/arrow-back.svg");

const SuccessfulSubmissionContainer = styled.div`
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  padding: ${defaultTheme.spacing.m};
  background: ${defaultTheme.colors.primary};
`;

const SuccessImageContainer = styled.div`
  width: 50%;
  min-width: 500px;

  ${respondTo.tablet`
  margin-top:${defaultTheme.spacing.m};
  width:90%;
  align-self:center;
  min-width:300px;
  `}

  ${respondTo.mobile`
  min-width:250px;
  `}
`;

const StyledImg = styled.img`
  width: 100%;
`;

const StyledLogo = styled.img`
  width: 300px;
  height: 250px;

  ${respondTo.mobile`
    width:200px;
    height:150px;
  `}
`;

const LogoContainer = styled.div`
  position: absolute;
  top: -5%;
  right: 0%;

  ${respondTo.tablet`
  right:0%;
  `}

  ${respondTo.mobile`
    top:-3%;
    right:0%;
  `}
`;

const SuccessContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 150px;
  padding: ${defaultTheme.spacing.m};
  flex: 2;

  ${respondTo.tablet`
  flex-direction:column;
  margin-top:80px;
  padding:${defaultTheme.spacing.s};
  `}

  ${respondTo.mobile`
  margin-top:75px;
  `}
`;
const SuccessText = styled.div`
  width: 40%;
  height: 50%;
  ${respondTo.tablet`
  width:100%;
  `}
`;

const SuccessGoBack = styled.img`
  cursor: pointer;
`;
export const SuccessfulSubmission = () => {
  const { text } = useText();
  return (
    <SuccessfulSubmissionContainer>
      <Align direction="column" justify="space-between" align="stretch">
        <div>
          <SuccessGoBack
            src={arrowBack}
            onClick={() => {
              navigate("/");
            }}
            alt="go back"
          />
        </div>
        <SuccessContent>
          <SuccessText>
            <Align direction="column" justify="space-between" align="center">
              <Title size="section" color={defaultTheme.colors.green} bold>
                {text("success.title")}
              </Title>
              <Text
                size="large"
                color={defaultTheme.colors.white}
                spacing={defaultTheme.spacing.m}
              >
                {text("success.text")}
              </Text>
              <Button
                title={text("success.button")}
                onPress={() => {
                  navigate("/");
                }}
              />
            </Align>
          </SuccessText>
          <SuccessImageContainer>
            <StyledImg src={messageSent} />
          </SuccessImageContainer>
        </SuccessContent>
      </Align>
      <LogoContainer>
        <StyledLogo src={logoGrey} />
      </LogoContainer>
    </SuccessfulSubmissionContainer>
  );
};
