import React, { useEffect } from "react";
import { WebSection } from "../../types/types";

export function useActiveSection(
  ref: React.RefObject<any>,
  setActiveSection: (id: WebSection) => void,
  sectionId: WebSection,
  rootMargin = 0
) {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setActiveSection(sectionId);
        }
      },
      {
        rootMargin: rootMargin === 0 ? `-300px` : `${rootMargin}px`,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []);
}
