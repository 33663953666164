import * as React from "react";
import { SuccessfulSubmission } from "../components/form/SuccessfulSubmission/SuccessfulSubmission";
import Metadata from "../components/layout/MetaData";
import { GlobalStyles } from "../styles/GlobalStyles";

const SuccessPage = () => {
  return (
    <>
      <GlobalStyles />
      <Metadata title="Success!" />
      <SuccessfulSubmission />
    </>
  );
};

export default SuccessPage;
